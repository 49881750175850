import React, { useState, useEffect } from "react";
import "./userdetails.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const UserDetails = ({ row, index, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [modalMode, setModalMode] = useState("edit");
  const [name, setName] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [colleges, setColleges] = useState([]);
  const [collegeId, setCollegeId] = useState("");
  const [selectedCollege, setSelectedCollege] = useState("");

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/college/list`);
        setColleges(response.data);
      } catch (error) {
        console.error("Unable to fetch colleges!", error.message);
      }
    };

    fetchColleges();
  }, []);

  useEffect(() => {
    if (modalMode === "edit") {
      setEditedEmail(row.email);
      setName(row.name);
      setSelectedCollege(row.college);
      setCollegeId(row.collegeId);
    }
  }, [modalMode, row]);

  const handleEditClick = () => {
    if (row.email) {
      setModalMode("edit");
      setIsModalOpen(true);
    }
  };

  const handleDeleteClick = () => {
    if (row.email) {
      setIsDeleteConfirmOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditedEmail("");
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        name,
        college: selectedCollege,
        role: "admin",
        email: editedEmail,
      };
      let response;
      if (modalMode === "edit") {
        response = await axios.put(
          `${SERVER_URL}/admin/${row._id}/update?collegeId=${collegeId}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.post(
          `${SERVER_URL}/admin/add?collegeId=${collegeId}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/admin/${row._id}/delete?collegeId=${collegeId}`
      );
      if (response.status === 200) {
        console.log("Delete confirmed");
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="user-details-row" key={row._id}>
      <div className="user-details-row-sl">
        <span>{index + 1}.</span>
      </div>
      <div className="user-details-row-email">
        <span>{row.college}</span>
      </div>
      <div className="user-details-row-role">
        <span>{row.email}</span>
      </div>
      <div className="user-details-row-action">
        <div className="user-row-button-wrapper">
          <div className="button" onClick={handleEditClick}>
            <div className="icon">
              <i className="bi bi-pencil-square"></i>
            </div>
            <span>Edit</span>
          </div>
          <div className="button" onClick={handleDeleteClick}>
            <div className="icon">
              <i className="bi bi-trash"></i>
            </div>
            <span>Delete</span>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <div className="edit-user-popup-container">
              <h2>{modalMode === "edit" ? "Edit User" : "Add User"}</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <select
                    value={selectedCollege}
                    onChange={(e) => {
                      setSelectedCollege(e.target.value);
                      const selected = colleges.find(
                        (college) => college._id === e.target.value
                      );
                      setCollegeId(selected._id);
                    }}
                    required
                  >
                    <option value="" disabled>
                      Select College
                    </option>
                    {colleges.map((college) => (
                      <option key={college._id} value={college._id}>
                        {college.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="college">College</label>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editedEmail}
                    onChange={(e) => setEditedEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label htmlFor="name">Name</label>
                </div>

                <div className="form-actions">
                  <button type="submit">
                    {modalMode === "edit" ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Confirm Delete</h1>
            <p>Are you sure you want to delete this user?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
