import React, { useCallback, useEffect, useState } from "react";
import "./dashboard.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import SearchBar from "../../components/searchbar/SearchBar";
import DashboardDetails from "../../components/dashboard/DashboardDetails";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchCollege = useCallback(async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`${SERVER_URL}/college/list`);
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      setError("Error loading data!");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCollege();
    setEmail("");
  }, [fetchCollege]);

  const filterData = (searchTerm) => {
    const filtered = data.filter((item) =>
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleSearch = (searchTerm) => {
    filterData(searchTerm);
  };

  const handleAddClick = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const postData = {
        name,
        email,
        address,
        contact,
      };

      const response = await axios.post(`${SERVER_URL}/college/add`, postData);

      if (response.status === 200 || response.status === 201) {
        await fetchCollege();
        setShowPopup(false);
        setEmail("");
        setName("");
        setAddress("");
        setContact("");
      }
    } catch (error) {
      setError("Error adding college!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="college-management-container">
      <div className="college-top">
        <div className="college-heading">
          <h1>
            <i className="bi bi-person"></i>College Management
          </h1>
          <span>College Management</span>
        </div>
        <div className="college-action">
          <SearchBar onSearch={handleSearch} />
          <div className="college-btn" onClick={handleAddClick}>
            <div className="icon">
              <i className="bi bi-plus-lg"></i>
            </div>
            <span>Add College</span>
          </div>
        </div>
      </div>
      <div className="college-details">
        <div className="college-details-top">
          <div className="college-details-top-sl">
            <span className="span-sl">SL No.</span>
          </div>
          <div className="college-details-top-role">
            <span className="span-role">College</span>
          </div>
          <div className="college-details-top-email">
            <span className="span-email">Email</span>
          </div>
          <div className="college-details-top-action">
            <span className="span-action">Action</span>
          </div>
        </div>
        <div className="college-details-container">
          {loading && <p>Loading...</p>}
          {error && <p className="error">{error}</p>}
          {filteredData.map((row, index) => (
            <DashboardDetails
              row={row}
              index={index}
              key={index}
              refreshData={fetchCollege}
            />
          ))}
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <div className="college-popup-container">
              <h2>Add Admin</h2>
              <form method="POST" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <label htmlFor="name">Name</label>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <label htmlFor="address">Address</label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="contact"
                    name="contact"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                  <label htmlFor="contact">Contact</label>
                </div>
                <div className="form-actions">
                  <button type="submit" disabled={loading}>
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
            {error && <p className="error">{error}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
