import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import './App.css';
import "./utils/browserEvents";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Main from "./components/main/Main";
import UserManagementMain from "./components/main/UserManagementMain";
import SideBar from "./components/sidebar/SideBar";
import Header from "./components/header/Header";
import Login from "./pages/login/Login";

function HeaderWrapper() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  return !isLoginPage && <Header />;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <HeaderWrapper />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<SideBar />}>
              <Route path="dashboard" element={<Main />} />
              <Route path="user-management" element={<UserManagementMain />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
