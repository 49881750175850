// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-email {
  font-size: 16px;
  font-weight: 700;
  color: #012970;
  padding: 5px 15px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 25px;
  margin-right: 30px;
}
.avatar-email::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 25px;
  padding: 2px;
  background: linear-gradient(90deg, #177AD6, #AF14EF);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/components/header/nav/navavatar/navavatar.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;EACA,oDAAA;EAGA,sEAAA;EAEA,uBAAA;EACA,WAAA;AAAJ","sourcesContent":[".avatar-email {\n  font-size: 16px;\n  font-weight: 700;\n  color: #012970;\n  padding: 5px 15px;\n  position: relative;\n  z-index: 1;\n  background-color: #fff;\n  border-radius: 25px;\n  margin-right: 30px;\n\n  &::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    border-radius: 25px;\n    padding: 2px;\n    background: linear-gradient(90deg, #177AD6, #AF14EF);\n    -webkit-mask: linear-gradient(#fff 0 0) content-box,\n      linear-gradient(#fff 0 0);\n    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);\n    -webkit-mask-composite: xor;\n    mask-composite: exclude;\n    z-index: -1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
