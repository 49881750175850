import React from "react";
import UserManagement from "../../pages/user-management/UserManagement";

const UserManagementMain = () => {
  return (
    <main id="main" className="main">
      <UserManagement />
    </main>
  );
};

export default UserManagementMain;
